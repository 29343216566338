/*==================================
home
==================================*/
.homeHeader
{
    text-align: center;
	z-index: 1;
}

.homeBackground
{
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}