.header
{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 20;
    backdrop-filter: blur(8px);
}

.main
{
    width: 100%;
    min-height: 100vh;
    /*padding: var(--ML_toolBar_height) 0;*/
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.footer
{
    width: 100%;
    z-index: 20;
    position: fixed;
    bottom: 0px;
    left: 0px;
}